import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCheckbox } from '@/components/RMCheckbox/RMCheckbox';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMInputMasked } from '@/components/RMInputMasked/RMInputMasked';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { createAccountForm } from '@/modules/account/account.form';
import { AccountSetupAvatarContainer } from '@/modules/account/containers/AccountSetupAvatar.container';
import { InputContainer, InputController } from '@/modules/form/container';
import { submitForm, useIsFormValid } from '@/modules/form/form';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { captureException } from '@/utils/captureException';

import { Link, LogoutLink, Page, PageContent, PageFooter } from './AccountNamePage.styles';

function InternalAccountNamePage() {
  const { userService, redirectService } = useServices();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const form = useMemo(
    () =>
      createAccountForm({
        firstName: personQuery.data?.name?.first,
        lastName: personQuery.data?.name?.last,
        phone: user?.communicationChannels.phone?.number ?? '',
      }),
    [personQuery.data?.name, user?.communicationChannels.phone?.number],
  );
  const isFormValid = useIsFormValid(form);

  const handleSubmit = useCallback(async () => {
    await submitForm(form, async ({ firstName, lastName, termsAccepted, phone }) => {
      try {
        await userService.updateUserProfile({
          name: { first: firstName, last: lastName },
          phone: phone.length > 0 ? { countryCode: '1', number: phone } : null,
          termsAccepted,
        });

        const redirect = await redirectService.consumeRedirect('account-setup');
        navigate(redirect ?? '/');
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [form, navigate, redirectService, userService]);

  return (
    <Page>
      <PageContent>
        <RMText type="serif" size="l" color="on-surface-primary">
          Welcome to Remento!
        </RMText>
        <RMSpacer direction="column" spacing="md" />
        <RMText type="sans" size="s" color="on-surface-primary">
          Let’s finish setting up your account.
        </RMText>

        <RMSpacer direction="column" spacing="2xl" />
        <AccountSetupAvatarContainer form={form} />
        <RMSpacer direction="column" spacing="2xl" />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <InputContainer form={form} path="firstName">
            {(props) => <RMInput id="first-name" label="First name" placeholder="John" {...props} />}
          </InputContainer>

          <RMSpacer direction="column" spacing="2xl" />

          <InputContainer form={form} path="lastName">
            {(props) => <RMInput id="last-name" label="Last name" placeholder="Doe" {...props} />}
          </InputContainer>

          <RMSpacer direction="column" spacing="2xl" />

          <InputController form={form} path="phone">
            {(props) => (
              <RMInputMasked
                id="user-phone-number"
                label="Phone number (optional)"
                placeholder="(XXX) XXX-XXXX"
                mask="(000) 000-0000"
                autoCapitalize="none"
                inputMode="tel"
                value={props.value ?? ''}
                error={props.error}
                unmask={true}
                onBlur={props.onBlur}
                onAccept={(unmaskedValue: string) => {
                  if (unmaskedValue !== props.value) {
                    props.onChange(unmaskedValue);
                  }
                }}
              />
            )}
          </InputController>
          <RMSpacer direction="column" spacing="sm" />
          <RMText type="sans" size="xs" color="on-surface-tertiary">
            Remento sends helpful reminders via text. Standard text message rates may apply. Unsubscribe at any time by
            messaging STOP or disabling in your account settings.
          </RMText>

          <RMSpacer direction="column" spacing="2xl" />

          <InputController form={form} path="termsAccepted">
            {(props) => (
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <RMCheckbox
                  checked={props.value ?? false}
                  onChange={props.onChange as (value: boolean) => void}
                  onBlur={props.onBlur}
                />
                <RMText type="sans" size="xs" color="on-surface-tertiary">
                  I agree to the{' '}
                  <Link
                    href="https://app.termly.io/document/terms-of-use-for-saas/a8cc7ba3-01da-4175-8040-c0059abd3c26"
                    target="_blank"
                  >
                    Remento Terms
                  </Link>
                  . Learn how we use and protect your data in our{' '}
                  <Link
                    href="https://app.termly.io/document/privacy-policy/0755769b-4d9f-4a87-b6e7-afd34802edc2"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </RMText>
              </div>
            )}
          </InputController>
        </form>
      </PageContent>

      <PageFooter>
        <RMButton background="primary" disabled={isFormValid === false} autoLoading fullWidth onClick={handleSubmit}>
          Continue
        </RMButton>

        <RMText type="sans" size="xs" color="on-surface-secondary">
          Not{' '}
          <RMText type="sans" bold size="xs" color="on-surface-secondary">
            {user?.communicationChannels.email}
          </RMText>
          {'? '}
          <LogoutLink onClick={() => signOut()}>Log out.</LogoutLink>.
        </RMText>
      </PageFooter>
    </Page>
  );
}

export function AccountNamePage() {
  return (
    <RementoPage type="default">
      <InternalAccountNamePage />
    </RementoPage>
  );
}
